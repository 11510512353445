import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Barbell Jump Squats 4×4\\@60% 1RM`}</p>
    <p>{`Ball Hamstring curls 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 EMOM:`}</p>
    <p>{`Minutes 1:00, 4:00, 7:00, 10:00, 13:00: `}<strong parentName="p">{`15/10-Calorie Bike`}</strong></p>
    <p>{`Minutes 2:00, 5:00, 8:00, 11:00, 14:00: `}<strong parentName="p">{`10-Alternating Pistols
(5/leg)`}</strong></p>
    <p>{`Minutes: 3:00, 6:00, 9:00, 12:00, 15:00: `}<strong parentName="p">{`20-V Ups`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      